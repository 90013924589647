// import '../scss/main.scss';
// const window = global.window;

// import './_main.js';



// Load Styles
import '../scss/main.scss';
import './plugins.js';

import $ from "jquery";

window.jQuery = $;

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";
// window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

// Loading bootstrap with optional features
initBootstrap({
  modal: true,
});


var externalLink;

(function ($) {
    $.expr[':'].external = function (obj) {
        return !obj.href.match(/^mailto:/)
            && (obj.hostname != location.hostname);
    };
    var processing;
    /** Custom Validator**/
    $.validator.addMethod("alphaonly", function (value, element) {
        var regex = new RegExp("^[a-zA-Z ]+$"); //[a-zA-Z0-9]
        var key = value;

        if (!regex.test(key)) {
            return false;
        }
        return true;
    }, "Please use only alphabetic characters or space.");

    //Validation for ABS form
    $.validator.addMethod("alphanum", function (value, element) {
        var regex = new RegExp("^[-a-zA-Z0-9_\\-!&#*'\" ]+$"); //[a-zA-Z0-9]
        var key = value;

        if (!regex.test(key)) {
            return false;
        }
        return true;
    }, "Please use only alphanumeric characters.");

    $.validator.addMethod("notempty", function (value, element) {
        var key = value,
            tempValue = $.trim(value);

        if (tempValue == '') {
            return false;
        }
        return true;
    }, "Please enter a value.");

    $.validator.addMethod("nophp", function (value, element) {
        value = _convertSpecialChars(value);
        if (/<\?[=|php]?[\s\S]*?\?>/i.test(value)) {
            return false;
        }
        return true;
    }, "Only plain text allowed.");

    $.validator.addMethod("nohtml", function (value, element) {
        value = _convertSpecialChars(value);
        if (/<\/?[a-z][\s\S]*>/i.test(value)) {
            return false;
        }
        return true;
    }, "No html allowed.");

    function _convertSpecialChars(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    if($('#current-year').length > 0){
        $('#current-year').text(new Date().getFullYear());
    }

    $('a:external').addClass('external-link');
    $('a[href$=".pdf"]').prop('target', '_blank').addClass('pdf');
    $('.external-link').attr('aria-describedby', 'off-site');
    $('.external-link:not(.bypass)').removeAttr('target');

    if($('#off-site').length > 0){
        var offsiteModal = new Modal(document.getElementById('off-site'), {});
        $('body').on('click', '.external-link:not(.bypass)', function (e) {
            e.preventDefault();
            externalLink = $(this).attr('href');
            offsiteModal.show();
        });
    }

    var shareModal = new Modal(document.getElementById('share-modal'), {});
    $('#share-modal').on('hidden.bs.modal', function () {
        _resetform();
    });
    $('#share-modal').on('shown.bs.modal', function () {
        $("#signup-frm").validate({
            ignore: ".ignore",
            errorElement: "span",
            errorPlacement: function (error, element) {
                error.appendTo(element.parents('.field-input'));
            },
            highlight: function (element) {
                $(element).parents('.field-input').addClass('has-error');
            },
            unhighlight: function (element) {
                $(element).parents('.field-input').removeClass('has-error');
            },
            rules: {
                'entry[firstname]': {nohtml: true, nophp: true },
                'entry[lastname]': {nohtml: true, nophp: true },
                'entry[email]': { required: true, email: true, notempty: true, nohtml: true, nophp: true },
                'entry[story]': { required: true, notempty: true, nohtml: true, nophp: true },
                'entry[agree]': {required: true },
                'entry[receive_news]': {required: true },
            },
            messages: {
                'entry[email]': { required: 'Please enter your email address.', email: 'Please enter a valid email address.' },
                'entry[story]': {required: 'Please enter your story.' },
            },
            invalidHandler: function (event, validator) {
                // $('html, body').animate({
                //     scrollTop: $(validator.errorList[0].element).parents('.field-input').offset().top
                // }, 800);
            },
            submitHandler: function (form) {
                //new FormData(this),
                processForm(new FormData(form));
                return false;
            }
        });
    });

    
    $('body').on('click', '.resource .share', function (e) {
        e.preventDefault();
        shareModal.show();
    });

   
    $('body').on('click', '.off-site-btns .continue', function (e) {
        e.preventDefault();
        window.open(externalLink);
        offsiteModal.hide();
    });

    function processForm(formData) {
        $('#error-bucket, #success-bucket').fadeOut().remove();
        if (processing) {
            alert('Your submission is being processed, please wait.');
            return;
        }

        var button = $('.frm-submit');
        button.addClass('loading');
        processing = true;
        formData.append('op', 'form-submission');

        $.ajax({
            type: "POST",
            dataType: 'json',
            url: 'misc/ajax.php',
            data: formData,
            contentType: false,
            cache: false,
            processData:false,
            beforeSend: function (xhr) {
                $("#err").fadeOut();
            }
        })
            .done(function (resp) {
                if (!isEmpty(resp.success)) {
                    $('#signup-frm').prepend('<div id="success-bucket"><div class="alert alert-success" role="alert">Submission processed successfuly.</div></div>');
                    _resetform();
                } else if (!isEmpty(resp.error)) {
                    if ($('#signup-frm').length > 0) {
                        $('#signup-frm').prepend('<div id="error-bucket"><div class="alert alert-danger" role="alert">' + resp.error + '</div></div>');
                    }
                }
            })
            .fail(function () {
                // Some Error Occured
            })
            .always(function (data) {
                processing = false;
                button.removeClass('loading');
                if ($('#error-bucket').length > 0) {
                    $('html, body').animate({
                        scrollTop: $('#error-bucket').offset().top - 10
                    }, 800);
                }
            });
    }

    $(window).on('load', function() {
        if ($(".animatecss").length > 0) {
            var $collection = $('.animatecss');
            $collection.each(function () {
                new Waypoint.Inview({
                    element: this,
                    enter: function (direction) {
                        console.log('entrer');
                        //if (!isMobile()) {
                            $(this.element).removeClass($(this.element).data("animation-out") + " opacity-0").addClass($(this.element).data('animation'));
                        //}
                    },
                    exited: function (direction) { //changed from exited to exit
                        //if (!isMobile()) {
                            // if ($(this.element).hasClass('anim2')) {
                            //     $(this.element).removeClass($(this.element).data("anim2"));
                            // }
                            $(this.element).removeClass($(this.element).data("animation")).addClass($(this.element).data("animation-out") + ' opacity-0');
                        //}
                    },
                })
            });
        }
    });


    function _resetform(){
        $("#signup-frm")[0].reset();
        $("#signup-frm span.error").remove();
        // if ($('#success-bucket').length > 0) {
        //     $('html, body').animate({
        //         scrollTop: $('#success-bucket').offset().top - 10
        //     }, 1000);
        // }
    }

    function isEmpty(mixed_var) {
        mixed_var = jQuery.trim(mixed_var);
        var key;
        if (mixed_var === '' || mixed_var === 0 || mixed_var === '0' || mixed_var === null || mixed_var === false || typeof mixed_var === 'undefined') {
            return true;
        }

        if (typeof mixed_var === 'object') {
            for (key in mixed_var) {
                return false;
            }
            return true;
        }
        return false;
    }

})(jQuery);